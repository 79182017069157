<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-organizer`"
        v-model="properties.organizer"
        outlined
        dense
        :label="$lang.labels.organizer"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="i_calendar-organizer"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-summary`"
        v-model="properties.summary"
        outlined
        dense
        :label="$lang.labels.summary"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="i_calendar-summary"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        :key="`${step.id}-description`"
        v-model="properties.description"
        outlined
        dense
        :label="$lang.labels.description"
        required
        :readonly="!canEdit"
        data-cy="i_calendar-description"
      />
    </v-col>

    <v-col cols="12" class="position-relative">
      <text-input-with-clock-button
        :key="`${step.id}-startTime`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.startTime"
        :label="$lang.labels.startTime"
        step-label="i_calendar"
        required
        :readonly="!canEdit"
        @change="handleChange('startTime', $event)"
        @openDateTimeModal="$emit('openDateTimeModal', 'startTime')"
      />
    </v-col>

    <v-col cols="12" class="position-relative">
      <text-input-with-clock-button
        :key="`${step.id}-endTime`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.endTime"
        :label="$lang.labels.endTime"
        step-label="i_calendar"
        required
        :readonly="!canEdit"
        @change="handleChange('endTime', $event)"
        @openDateTimeModal="$emit('openDateTimeModal', 'endTime')"
      />
    </v-col>

    <v-col cols="12" class="pb-2">
      <calendar-participants-name-email-list
        :key="`${step.id}-participants`"
        :can-edit="canEdit"
        :data="properties.participants"
        :single-step="step"
        :steps="steps"
        @dataChanged="handleChange('participants', $event)"
      />
    </v-col>

    <v-col cols="12" class="position-relative">
      <text-input-with-clock-button
        :key="`${step.id}-alarmTime`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.alarm.alarmTime"
        :label="$lang.labels.alarmTime"
        step-label="i_calendar"
        required
        :readonly="!canEdit"
        @change="handleChange('alarm.alarmTime', $event)"
        @openDateTimeModal="$emit('openDateTimeModal', 'alarm.alarmTime')"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        :key="`${step.id}-alarmDescription`"
        v-model="properties.alarm.alarmDescription"
        outlined
        dense
        :label="$lang.labels.alarmDescription"
        required
        :readonly="!canEdit"
        data-cy="i_calendar-alarmDescription"
      />
    </v-col>

    <v-col cols="12">
      <v-combobox
        :key="`${step.id}-frequencyTemp`"
        v-model="frequencyTemp"
        outlined
        dense
        clearable
        :items="SUPPORTED_RECURRING_TYPES"
        :label="$lang.labels.frequency"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="i_calendar-frequency"
        @change="handleChange('frequency', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-combobox
        :key="`${step.id}-frequencyIntervalTemp`"
        v-model="frequencyIntervalTemp"
        outlined
        dense
        :items="frequencyIntervalsFromFrequency"
        :label="$lang.labels.frequencyInterval"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="i_calendar-frequencyInterval"
        @change="handleChange('frequencyInterval', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-status`"
        v-model="properties.status"
        outlined
        dense
        :label="$lang.labels.status"
        required
        :readonly="!canEdit"
        data-cy="i_calendar-status"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-url`"
        v-model="properties.url"
        outlined
        dense
        :label="$lang.labels.url"
        required
        :readonly="!canEdit"
        data-cy="i_calendar-url"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-location`"
        v-model="properties.location"
        outlined
        dense
        :label="$lang.labels.location"
        required
        :readonly="!canEdit"
        data-cy="i_calendar-location"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="i_calendar-targetObject"
      />
    </v-col>
  </v-row>
</template>

<script>
import { TextInputWithClockButton } from './components'
import StepMixin from './StepMixin'
import CalendarParticipantsNameEmailList from '../CalendarParticipantsNameEmailList.vue'

import { SUPPORTED_RECURRING_TYPES } from '@/utils/constants'

export default {
  name: 'ICalendarStep',
  components: {
    CalendarParticipantsNameEmailList,
    TextInputWithClockButton
  },
  mixins: [StepMixin],
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      SUPPORTED_RECURRING_TYPES,
      frequencyTemp: '',
      frequencyIntervalTemp: ''
    }
  },
  computed: {
    frequencyIntervalsFromFrequency() {
      switch (this.properties.recurrence.frequency) {
      case '\'SECONDLY\'':
        return Array.from({ length: 60 }, (_, index) => index + 1)
      case '\'MINUTELY\'':
        return ['1', '2', '3', '4', '5', '10', '15', '20', '30', '45']
      case '\'HOURLY\'':
        return ['1', '2', '3', '4', '6', '8', '12', '24']
      case '\'DAILY\'':
      case '\'WEEKLY\'':
        return ['1', '2', '3', '4', '5', '6', '7', '14', '21', '28']
      case '\'MONTHLY\'':
      case '\'YEARLY\'':
        return ['1', '2', '3', '4', '6', '12']
      default:
        return Array.from({ length: 60 }, (_, index) => String(index + 1))
      }
    }
  },
  watch: {
    frequencyTemp: {
      handler(val) {
        if (val) {
          this.properties.recurrence.frequency = val
        } else {
          this.properties.recurrence.frequency = null
        }
      }
    },
    frequencyIntervalTemp: {
      handler(val) {
        if (val) {
          this.properties.recurrence.frequencyInterval = val
        } else {
          this.properties.recurrence.frequencyInterval = null
        }
      }
    }
  },
  created() {
    if (!this.properties.alarm) this.properties.alarm = {
      alarmTime: '',
      alarmDescription: ''
    }
    if (!this.properties.participants) this.properties.participants = []
    if (!this.properties.recurrence) this.properties.recurrence = {
      frequency: '',
      frequencyInterval: ''
    }
    if (this.properties.recurrence.frequency) this.frequencyTemp = this.properties.recurrence.frequency
    if (this.properties.recurrence.frequencyInterval) this.frequencyIntervalTemp = this.properties.recurrence.frequencyInterval

  }
}
</script>
