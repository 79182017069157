<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6">
        <h3>{{ headerLabel }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ name: '', email: '' })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col cols="1">
              <p class="m-0 p-0 pt-3 color-primary">{{ i + 1 }}.</p>
            </v-col>
            <v-col :cols="hasOptionalCheckbox ? 4 : 5">
              <v-text-field
                v-model="item.name"
                dense
                :label="$lang.labels.name"
                :readonly="!canEdit"
                :rules="[$v => !!$v || $lang.labels.required]"
                :class="!hasOptionalCheckbox && 'pr-1'"
              />
            </v-col>
            <v-col :cols="hasOptionalCheckbox ? 4 : 5">
              <v-text-field
                v-model="item.email"
                dense
                :label="$lang.labels.email"
                :readonly="!canEdit"
                :rules="[$v => !!$v || $lang.labels.required]"
                :class="hasOptionalCheckbox ? 'ml-2' : 'pl-1'"
              />
            </v-col>
            <v-col v-if="hasOptionalCheckbox" cols="1">
              <v-checkbox
                v-model="item.optional"
                dense
                :label="$lang.labels.optional"
                class="attendees-optional-checkbox ml-8"
                @change="handleChangeOptionalCheckbox(i, $event)"
              />
            </v-col>
            <v-col cols="1" justify="flex-end" class="justify-end text-right" :class="hasOptionalCheckbox && 'ml-6'">
              <v-btn
                class="mx-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="i !== deconstructedData.length - 1" cols="12" class="my-1">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CalendarParticipantsNameEmailList',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    steps: {
      type: Array,
      default: () => {
        return []
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    },
    hasOptionalCheckbox: {
      type: Boolean,
      default: false
    },
    headerLabel: {
      type: String,
      default() {
        return this.$lang.labels.participants
      }
    }
  },
  data: () => ({
    deconstructedData: []
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler(val) {
        this.$emit('dataChanged', val)
      },
      deep: true
    }
  },
  created() {
    if (this.data && this.data.length > 0) {
      this.deconstructedData = this.data
    }
    if (this.hasOptionalCheckbox) {
      this.deconstructedData.forEach((item) => {
        item.optional = item.type === 'optional'
      })
    }
  },
  methods: {
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    handleChangeOptionalCheckbox(i, event) {
      if (event) {
        this.deconstructedData[i].type = 'optional'
      } else {
        this.deconstructedData[i].type = 'required'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
.attendees-optional-checkbox::v-deep {
  .v-input__slot {
    flex-direction: column;
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}
</style>
